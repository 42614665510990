'use strict';

import { isEmpty, isUndefined } from 'lodash';

const REL_ALTERNATE = 'alternate';

export default {
    computed: {
        seoEntry() {
            return this.entry;
        },
        seoTitle() {
            if (this.seoEntry) {
                if (this.seoEntry) {
                    return `${this.seoEntry?.seo?.title ?? this.seoEntry?.title} | ${this.$config.site.name}`;
                }
            }

            return this.$config.site.name;
        },
        seoDescription() {
            if (this.seoEntry) {
                if (this.seoEntry.seo && this.seoEntry.seo.description) {
                    return this.seoEntry.seo.description;
                } else if (this.seoEntry.description) {
                    return this.seoEntry.description;
                }
            }

            return this.$config.site.description;
        },
        seoImage() {
            if (this.seoEntry) {
                if (this.seoEntry.featuredImage && this.seoEntry.featuredImage[0] && this.seoEntry.featuredImage[0].w768) {
                    return this.seoEntry.featuredImage[0];
                } else if (this.seoEntry.image && this.seoEntry.image[0] && this.seoEntry.image[0].w768) {
                    return this.seoEntry.image[0];
                } else if (this.seoEntry.imageMobile && this.seoEntry.imageMobile[0] && this.seoEntry.imageMobile[0].w768) {
                    return this.seoEntry.imageMobile[0];
                } else if (this.seoEntry.imageCard && this.seoEntry.imageCard[0] && this.seoEntry.imageCard[0].w768) {
                    return this.seoEntry.imageCard[0];
                }
            }
            return '';
        },
        seoSocial() {
            return this?.seoEntry?.seo?.social ?? {};
        },
        seoFacebook() {
            return this?.seoSocial?.facebook ?? {};
        },
        seoTwitter() {
            return this?.seoSocial?.twitter ?? {};
        },
        seoFacebookImage() {
            return this?.seoFacebook?.image ?? {};
        },
        seoTwitterImage() {
            return this?.seoTwitter?.image ?? {};
        },
        seoRobots() {
            return this?.seoEntry?.seo?.advanced?.robots?.map((content) => {
                return { name: 'robots', content };
            }) ?? [];
        },
        seoCanonical() {
            return this?.seoEntry?.seo?.advanced?.canonical;
        },
        seoMeta() {
            return [
                { hid: 'description', name: 'description', content: this.seoDescription || '' },
                // FACEBOOK
                { hid: 'og:type', property: 'og:type', content: 'website' },
                { hid: 'og:title', property: 'og:title', content: this.seoFacebook.title || this.seoTitle },
                { hid: 'og:description', property: 'og:description', content: this.seoFacebook.description || this.seoDescription || '' },
                { hid: 'og:image', property: 'og:image', content: this.seoFacebookImage.w768 || this.seoImage.w768 || '' },
                // TWITTER
                { hid: 'twitter:title', property: 'twitter:title', content: this.seoTwitter.title || this.seoTitle },
                { hid: 'twitter:description', property: 'twitter:description', content: this.seoTwitter.description || this.seoFacebook.description || this.seoDescription || '' },
                { hid: 'twitter:image', property: 'twitter:image', content: this.seoTwitterImage.w768 || this.seoFacebookImage.w768 || this.seoImage.w768 || '' }
            ];
        },
        seoAttributeLinks() {
            const i18nLinks = this.$nuxtI18nHead({ addSeoAttributes: true })?.link ?? [];
            const links = i18nLinks.filter((link) => {
                // If localized urls are provided from the server, remove nuxt generated urls.
                if (link.rel === REL_ALTERNATE && this.entry && !isUndefined(this.entry.localized)) {
                    return false;
                }

                // filter out localizations we explicitly exclude from config.
                if (link.rel === REL_ALTERNATE) {
                    const found = this.$i18n.locales.find(locale => locale.code === link.hreflang);
                    return !found?.excludeAsAlternate ?? true;
                }

                if (this.seoCanonical && link.rel === 'canonical') {
                    link.href = this.seoCanonical;
                }

                return true;
            });

            // If localized urls are provided from the server, use those!
            if (!isEmpty(this.entry?.localized)) {
                this.entry.localized.forEach(({ language, url }) => {
                    links.push({
                        hid: `i18n-alt-${language}`,
                        href: url.endsWith('/') ? url : `${url}/`,
                        hreflang: language,
                        rel: REL_ALTERNATE
                    });
                });

                // Re-add self-referential link
                links.push(
                    i18nLinks.find((link) => {
                        return link.hreflang === this.$i18n.locale;
                    })
                );
            }

            return links;
        },
        seoHead() {
            const nuxtI18nHead = this.$nuxtI18nHead({
                addSeoAttributes: true,
                canonicalQueries: ['page', 'search']
            });

            return {
                ...nuxtI18nHead,
                title: this.seoTitle,
                meta: [
                    ...this.seoMeta,
                    ...this.seoRobots
                ],
                link: this.seoAttributeLinks
            };
        }
    },
    head() {
        return this.seoHead;
    }
};
