
export default {
    props: {
        sections: {
            type: Array,
            required: true,
            default: () => { return []; }
        }
    },

    data() {
        /* eslint-disable camelcase */
        return {
            map: {
                flexibleSections_richText_BlockType: 'FlexibleSectionsBlocksRichText',
                flexibleSections_image_BlockType: 'FlexibleSectionsBlocksImage',
                flexibleSections_textImage_BlockType: 'FlexibleSectionsBlocksTextImage',
                flexibleSections_textVideo_BlockType: 'FlexibleSectionsBlocksTextVideo',
                flexibleSections_contactInfo_BlockType: 'FlexibleSectionsBlocksContactInfo',
                flexibleSections_video_BlockType: 'FlexibleSectionsBlocksVideo',
                flexibleSections_herohome_BlockType: 'FlexibleSectionsBlocksHeroHome',
                flexibleSections_heroProgram_BlockType: 'FlexibleSectionsBlocksHeroProgram',
                flexibleSections_heroContent_BlockType: 'FlexibleSectionsBlocksHeroContent',
                flexibleSections_pageIntro_BlockType: 'FlexibleSectionsBlocksPageIntro',
                flexibleSections_cards_horizontal_BlockType: 'FlexibleSectionsBlocksCardListHorizontal',
                flexibleSections_cards_grid_BlockType: 'FlexibleSectionsBlocksCardListGrid',
                flexibleSections_cards_custom_BlockType: 'FlexibleSectionsBlocksCardListCustom',
                flexibleSections_anchorNav_BlockType: 'FlexibleSectionsBlocksAnchorNav',
                flexibleSections_anchor_BlockType: 'FlexibleSectionsBlocksAnchor',
                flexibleSections_accordion_BlockType: 'FlexibleSectionsBlocksAccordion',
                flexibleSections_worldMap_BlockType: 'FlexibleSectionsBlocksWorldMap',
                flexibleSections_hubspotForm_BlockType: 'FlexibleSectionsBlocksHubspotForm',
                flexibleSections_learningTools_BlockType: 'FlexibleSectionsBlocksLearningTools',
                flexibleSections_programs_BlockType: 'FlexibleSectionsBlocksPrograms',
                flexibleSections_latestBlogs_BlockType: 'FlexibleSectionsBlocksLatestBlogs',
                flexibleSections_relatedBlogs_BlockType: 'FlexibleSectionsBlocksRelatedBlogs',
            }
        };
        /* eslint-enable camelcase */
    }
};
