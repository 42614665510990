
import { trim } from 'lodash';
import getHome from '~/graphql/queries/getHome.graphql';
import seo from '~/mixins/seo';

export default {
    name: 'PageIndex',

    mixins: [seo],

    data() {
        return {
            entry: null,
            notFound: false
        };
    },

    async fetch() {
        const site = this.$i18n.localeProperties.craftSiteHandle;
        const uri = trim(this.$route.params.pathMatch, '/');
        const { data } = await this.$gql.executeQuery(
            getHome,
            { site, uri }
        );

        if (data && data.entry) {
            this.entry = data.entry;
        } else {
            this.notFound = true;

            if (process.server) {
                this.$nuxt.context.res.statusCode = 404;
            }
        }
    }
};
